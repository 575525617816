<template>
    <div>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Meus Indicados</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body rounded-b-md">
                <b-tabs v-model="activeTab">
                    <b-tab-item label="Pendentes">
                        <ul v-for="(pessoa, index) in pendentes" :key="pessoa">
                            <li>{{`${index + 1}: ${pessoa}`}}</li>
                        </ul>
                    </b-tab-item>
                    <b-tab-item label="Aprovados">
                        <ul v-for="(pessoa, index)  in aprovados" :key="pessoa">
                            <li>{{`${index + 1}: ${pessoa}`}}</li>
                        </ul>
                    </b-tab-item>
                    <b-tab-item label="Não aprovados">
                        <ul v-for="(pessoa, index)  in naoAprovados" :key="pessoa">
                            <li>{{`${index + 1}: ${pessoa}`}}</li>
                        </ul>
                    </b-tab-item>
                </b-tabs>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    name: "Indicadores",
    //Esse componente recebe 3 vetores de strings: pendentes, aprovados e naoAprovados
    props: {
        pendentes: Array,
        aprovados: Array,
        naoAprovados: Array
    },
    data() {
        return {
            activeTab: 0,
        };
    },
};
</script>
<template>
    <div>
        <b-modal v-model="isModalActive" has-modal-card :can-cancel="false">
            <indicadores
                @close="isModalActive = false"
                :pendentes="pendentesArray"
                :aprovados="aprovadosArray"
                :naoAprovados="naoAprovadosArray"
            ></indicadores>
        </b-modal>

        <div v-if="waiting" class="py-56">
            <b-notification class="p-8 bg-white" :closable="false">
                <b-loading
                    :is-full-page="false"
                    v-model="waiting"
                    :can-cancel="true"
                ></b-loading>
            </b-notification>
        </div>

        <div v-else class="flex flex-col items-center">
            <main v-if="maiorDeIdade" class="mb-5">
                <div class="flex justify-between">
                    <div class="p-2">
                        <a class="text-3xl" @click="$router.go(-1)"
                            ><i class="jam jam-chevron-left"></i
                        ></a>
                    </div>

                    <img
                        v-if="dsCode"
                        class="w-64 justify-self-center"
                        src="./../../assets/img/amigo-medclub/logo.svg"
                    />

                    <img
                        v-else
                        class="w-64 justify-self-center mb-5 mt-2"
                        src="./../../assets/img/amigo-medclub/logo2.jpeg"
                    />

                    <div class="p-2 invisible">
                        <a class="text-3xl"
                            ><i class="jam jam-chevron-left"></i
                        ></a>
                    </div>
                </div>

                <!-- Indicadores -->
                <div
                    class="
                        transform
                        hover:scale-105
                        transition
                        duration-150
                        cursor-pointer
                        flex-col
                        items-center
                        w-auto
                        sm:w-6/12
                        mx-auto
                        justify-between
                        mb-4
                        mt-2
                        p-4
                        rounded-md
                    "
                    style="box-shadow: 0 0 1rem rgba(1, 1, 1, 0.1)"
                    v-if="dsCode"
                    @click="exibeModal"
                >
                    <header
                        class="font-bold text-blue-800 text-center mb-2 text-xl"
                    >
                        <span v-if="quantidadeTotal === 0"
                            >Nenhum amigo convidado</span
                        >
                        <span v-else
                            >{{ quantidadeTotal }}
                            <span v-if="quantidadeTotal === 1"
                                >amigo convidado</span
                            >
                            <span v-else>amigos convidados</span></span
                        >
                    </header>
                    <div
                        class="mx-auto w-10/12 border-gray-200 mb-2"
                        style="border-width: 1px"
                    ></div>
                    <main class="flex md:flex-row">
                        <div class="mx-auto"></div>

                        <div class="flex-col">
                            <div class="text-center">Pendentes</div>
                            <div
                                class="text-center text-2xl"
                                style="color: #ff9c03"
                            >
                                {{ pendentes }}
                            </div>
                        </div>
                        <div
                            class="mx-auto h-10/12 border-gray-200"
                            style="border-width: 1px"
                        ></div>

                        <div>
                            <div class="text-center">Aprovados</div>
                            <div class="text-center text-2xl text-green-400">
                                {{ aprovados }}
                            </div>
                        </div>
                        <div
                            class="mx-auto h-10/12 border-gray-200"
                            style="border-width: 1px"
                        ></div>

                        <div>
                            <div class="text-center">Não aprovados</div>
                            <div class="text-center text-2xl text-red-400">
                                {{ naoAprovados }}
                            </div>
                        </div>

                        <div class="mx-auto"></div>
                    </main>
                </div>

                <div class="text-center">
                    <h1 class="font-bold text-xl mb-4" v-if="dsCode">
                        Amigo medclub <span class="text-blue-600">R$ 10</span>
                    </h1>
                    <h1 class="font-bold text-xl mb-4" v-else>
                        Indique e Ganhe R$10
                    </h1>
                </div>

                <div class="text-center mx-auto mb-4 w-4/6">
                    Compartilhe seu código com amigos que ainda não se
                    cadastraram no Medclub. Você pode ganhar até R$ 10 em
                    créditos Medclub.
                </div>

                <div
                    class="mx-auto w-56 bg-gray-200 rounded-lg p-2 mb-4"
                    v-if="dsCode"
                >
                    <div class="text-center">Seu código é:</div>
                    <div class="text-center">
                        <strong>{{ dsCode }}</strong>
                    </div>
                </div>

                <div class="w-56 mx-auto mb-4">
                    <b-button
                        class="
                            w-full
                            rounded-lg
                            p-6
                            text-white
                            hover:text-white
                        "
                        @click="copyCode"
                        style="background-color: #407bff"
                        v-if="dsCode"
                    >
                        <img
                            class="w-5 mr-1 inline"
                            src="./../../assets/img/amigo-medclub/copy.png"
                        />
                        Copiar link de convite</b-button
                    >

                    <b-button
                        class="w-full rounded-full p-6"
                        type="is-primary"
                        @click="$parent.$parent.dialog.login = true"
                        v-else
                    >
                        Entre para indicar</b-button
                    >
                </div>

                <h2 class="text-center text-lg font-bold mb-5">
                    Como convidar amigos
                </h2>

                <footer class="flex flex-wrap items-center justify-center mb-4">
                    <div class="mb-2 p-4">
                        <img
                            src="./../../assets/img/amigo-medclub/passo1.png"
                            class="w-20 mb-4 mx-auto"
                        />
                        <p class="text-center mx-auto">
                            Compartilhe o código acima com<br />seus amigos.
                        </p>
                    </div>

                    <div class="mb-2 p-4">
                        <img
                            src="./../../assets/img/amigo-medclub/passo2.png"
                            class="w-20 mb-4 mx-auto"
                        />
                        <p class="text-center mx-auto">
                            Eles poderão utilizar seu código <br />para se
                            cadastrar no app.
                        </p>
                    </div>

                    <div class="mb-2 p-4">
                        <img
                            src="./../../assets/img/amigo-medclub/passo3.png"
                            class="w-20 mb-4 mx-auto"
                        />
                        <p class="text-center mx-auto">
                            Se a primeira compra da pessoa indicada<br />
                            for a partir de R$ 90, você ganha R$ 10
                        </p>
                    </div>
                </footer>

                <div class="text-center mb-2">
                    <a
                        href="public/regulamento-amigo-medclub.pdf"
                        target="_blank"
                        class="text-blue-600 underline"
                        >Conheça as regras</a
                    >
                </div>
            </main>

            <main
                v-else
                class="flex justify-between"
                style="height: 70vh; min-width: 20rem; width: 57vw"
            >
                <div class="p-2">
                    <a class="text-3xl" @click="$router.go(-1)"
                        ><i class="jam jam-chevron-left"></i
                    ></a>
                </div>

                <h1 class="self-center text-2xl">
                    Amigo Medclub está disponível apenas para usuários maiores
                    de idade.
                </h1>

                <div class="invisible">.</div>
            </main>
        </div>
    </div>
</template>

<style lang="scss" scoped>
button:active {
    filter: brightness(90%);
    transition-property: -moz-filter, -ms-filter, -o-filter, -webkit-filter,
        filter;
    transition-duration: 0.1s;
}
</style>

<script>
import { Usuario } from "../../middleware";
import Indicadores from "./Indicadores";
import moment from "moment";
moment.locale("pt-br");

export default {
    components: { Indicadores },
    data() {
        return {
            dsCode: "",
            maiorDeIdade: false,
            waiting: true,
            quantidadeTotal: 0,
            pendentes: 0,
            aprovados: 0,
            naoAprovados: 0,
            pendentesArray: [],
            aprovadosArray: [],
            naoAprovadosArray: [],
            isModalActive: false,
        };
    },
    created() {
        //Obtém o código de indicador do usuário logado
        if (this.$parent.$parent.usuario) {
            Usuario.getUser(this.$parent.$parent.usuario.id).then(
                (response) => {
                    this.dsCode = response.data.ds_codigo;
                    //Verifica se o usuário é maior de 18 anos
                    var birthDate = this.$parent.$parent.usuario.dt_nascimento;

                    const now = moment(new Date()); // Data de hoje
                    const past = moment(birthDate); // Outra data no passado
                    const duration = moment.duration(now.diff(past));

                    if (parseInt(duration.asYears()) >= 18) {
                        this.maiorDeIdade = true;
                    }
                    this.waiting = false;
                }
            );

            Usuario.getMeusIndicados().then((response) => {
                //Pega as quantidades
                this.aprovados = response.data.aprovados.length;
                this.naoAprovados = response.data.nao_aprovados.length;
                this.pendentes = response.data.pendentes.length;
                this.quantidadeTotal =
                    this.aprovados + this.naoAprovados + this.pendentes;

                // Preenche os vetores de indicadores
                this.pendentesArray = response.data.pendentes.map(pessoa => (
                    pessoa.nm_indicado
                ))
                this.aprovadosArray = response.data.aprovados.map(pessoa => (
                    pessoa.nm_indicado
                ))
                this.naoAprovadosArray = response.data.nao_aprovados.map(pessoa => (
                    pessoa.nm_indicado
                ))
              
            });
        } else {
            this.waiting = false;
            this.maiorDeIdade = true;
        }
    },
    methods: {
        copyCode() {
            //Pega a url atual, que é diferente quando roda localmente ou em produção
            const url = window.location.href.replace("/area-amigo-medclub", "");

            //Insere o link na área de transferência
            const el = document.createElement("textarea");
            el.value = `${url}/convite/${this.dsCode}`;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            //Exibe o toast de código copiado
            this.$buefy.toast.open({
                type: "is-success",
                position: "is-bottom",
                message: "Link copiado<br/>para a área de transferência",
            });
        },
        exibeModal() {
            this.isModalActive = true;
        },
    },
};
</script>